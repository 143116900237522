@layer components {
    p {
        @apply text-paragraph;
    }

    .btn {
        @apply rounded-lg px-24 py-6;
        transition: all 0.35s;

        &:disabled {
            opacity: 0.5;
            filter: grayscale(33%);
            cursor: not-allowed;
        }

        &.btn-sm {
            @apply px-8 py-4;
        }

        &.btn-xs {
            @apply px-6 py-2 text-sm;
        }
    }

    .btn-primary {
        @apply bg-primary text-white;
        &:hover,
        &:focus {
            @apply border-primary;
        }
    }

    .btn-outline-primary {
        @apply text-primary relative;
        background: transparent;

        &:before {
            @apply bg-primary absolute bottom-0 left-0 right-0 top-0 rounded-lg;
            content: '';
            border: 2px solid transparent;
            mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#fff, #fff);
            mask-composite: exclude;
        }

        &:hover {
            &:before {
                background: linear-gradient(-45deg, #50b1b8 50%, #406d7e) border-box;
                //background: linear-gradient(-45deg, theme('colors.secondary.DEFAULT') 40%, theme('colors.primary.DEFAULT')) border-box;
            }
        }

        &.rounded-l-none {
            &:before {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    .btn-secondary {
        @apply bg-secondary text-white;
        &:hover,
        &:focus {
            @apply border-secondary;
        }
    }

    .btn-outline-secondary {
        @apply text-secondary relative;
        background: transparent;

        &:before {
            @apply bg-secondary absolute bottom-0 left-0 right-0 top-0 rounded-lg;
            content: '';
            border: 2px solid transparent;
            mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#77c5b8, #77c5b8);
            mask-composite: exclude;
        }

        &:hover {
            &:before {
                background: linear-gradient(-45deg, #2f6e80 50%, #77c5b8) border-box;
            }
        }

        &.rounded-l-none {
            &:before {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    .btn-warning {
        @apply bg-warning border-warning text-white;
        &:hover,
        &:focus {
            @apply border-warning;
        }
    }

    .btn-outline-warning {
        @apply text-warning relative;
        background: transparent;

        &:before {
            @apply bg-warning absolute bottom-0 left-0 right-0 top-0 rounded-lg;
            content: '';
            border: 2px solid transparent;
            mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#fff, #fff);
            mask-composite: exclude;
        }

        &:hover {
            &:before {
                background: linear-gradient(-45deg, #fb9090 50%, #ff0000) border-box;
            }
        }

        &.rounded-l-none {
            &:before {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
}
