.card {
    @apply bg-secondary-lighter rounded-xl outline outline-[3px] outline-transparent;

    .card-header {
        @apply relative rounded-t-lg;
    }

    .card-body {
        @apply grow px-4 pb-2 pt-2;

        &:first-child {
            @apply rounded-t-lg;
        }

        &:last-child {
            @apply rounded-b-lg;
        }
    }

    .card-footer {
        @apply rounded-b-lg p-4;
    }
}

.secondary-selected {
    @apply outline-secondary shadow-secondary shadow-[0rem_0rem_2rem_.2rem_rgba(0,0,0,0)];
}

.primary-selected {
    @apply outline-primary shadow-primary shadow-[0rem_0rem_2rem_.2rem_rgba(0,0,0,0)];
}

.paragraph-selected {
    @apply outline-paragraph shadow-paragraph shadow-[0rem_0rem_2rem_.2rem_rgba(0,0,0,0)];
}

.custom-translate-exclamation-icon {
    transform: translate(calc(1px - 50%), -50%);
}
