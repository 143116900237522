html {
    scroll-behavior: smooth;
}

html,
body {
    min-height: 100%;
    font-size: 14px;
}

@media print {
    html,
    body {
        font-size: 12px;
    }
}

body {
    margin: 0;
    font-family: Poppins, Roboto, 'Helvetica Neue', sans-serif;
    @apply text-paragraph;
}

p {
    line-height: 1.7;
}

fa-icon {
    @apply text-primary;
    @apply text-lg;
}

ul {
    list-style: disc outside;
    margin: 1rem 0 0 1rem;
    line-height: 1.7;
}

.bg-darken {
    background: rgba(0, 0, 0, 0.1);
}

rza-mean-bundle rza-mean-card [rzameanlistbullet] * {
    @apply text-primary;
}

rza-mean-card [rzameanlistbullet] * {
    @apply text-white;
}

.shiny {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:before {
        content: '';
        height: 0;
        left: calc(var(--x, 0) * 1px);
        padding-bottom: 400%;
        position: absolute;
        top: calc(var(--y, 0) * 1px);
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s ease-in-out;
        width: 400%;
        z-index: -1;
    }

    &.bg-primary,
    &.btn-primary {
        &:before {
            background: theme('colors.primary.DEFAULT');
            background: radial-gradient(circle, #406d7e 0, #50b1b8 30%, #77c5b8 60%);
            //background: radial-gradient(circle, theme('colors.primary.DEFAULT') 0, theme('colors.secondary.DEFAULT') 30%);
        }
    }

    &.bg-secondary,
    &.btn-secondary {
        &:before {
            background: theme('colors.secondary.DEFAULT');
            background: radial-gradient(circle, #77c5b8 0, #50b1b8 30%, #406d7e 60%);
            //background: radial-gradient(circle, theme('colors.secondary.DEFAULT') 0, theme('colors.primary.DEFAULT') 30%);
        }
    }

    &:hover:not(:disabled) {
        &:before {
            opacity: 1;
        }
    }
}
